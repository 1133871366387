import {httpProvider} from "@Newfiling/Services";

export const fetchItems = (data) => {
    return httpProvider.post('/eAdminfindemployees', data);
};

export const store = (data) => {
    return httpProvider.post('/NewAdmin', data);
};

export const update = ( data) => {
    return httpProvider.put('/NewAdmin/', data);
};

export const find = (id) => {
    return httpProvider.post('/eAdminfindemployees', {
        userid: id
    });
};

export const fetchPermissions = () => {
    return httpProvider.post('/Permissioninformation', {});
};

export const updatePermissions = (data) => {
    return httpProvider.post('/AdminPermission', data);
};

export const fetchAdminStat = (data) => {
    return httpProvider.post('/Adminstate/userstate', data);
};
