<template>
  <div class="page-login-desktop">
    <div
        v-if="initLoading"
        class="pa-16 d-flex align-center justify-center">
      <v-progress-circular
          color="main"
          indeterminate
      />
    </div>

    <v-card flat

            v-else
    >
      <v-card-title class="text-body-1 font-weight-bold d-flex align-center justify-space-between">
        ویرایش مدیر
        <v-btn
            @click="$router.back()"
            text color="main" large>
          بازگشت
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="3">
            <v-text-field
                outlined
                label="نام و نام خانوادگی"
                hide-details
                v-model="admin.nameandfamily"
            />
          </v-col>
          <v-col :cols="3">
            <v-text-field
                readonly
                outlined
                label="شماره موبایل"
                hide-details
                v-model="admin.mobile"
                disabled
            />
          </v-col>
          <v-col :cols="3">
            <v-select
                outlined
                label="نوع مدیر"
                hide-details
                v-model="admin.accountType"
                :items="adminTypes"
                item-value="id"
                item-text="name"
            />
          </v-col>
          <v-col :cols="3">
            <v-text-field
                outlined
                label="شماره ثابت تماس"
                hide-details
                v-model="admin.phone"
            />
          </v-col>
          <v-col :cols="3">
            <v-text-field
                outlined
                label="ایمیل"
                hide-details
                v-model="admin.email"
            />
          </v-col>
          <v-col :cols="9">
            <v-text-field
                outlined
                label="آدرس"
                hide-details
                v-model="admin.adress"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-space-between py-6">
        <v-switch
            v-model="admin.userstatus"
            label="وضعیت فعال بودن کاربر"
            dense
            inset
            hide-details
        />
        <v-btn
            :loading="admin.loading"
            :disabled="admin.loading || !(admin.nameandfamily && admin.mobile && admin.accountType && admin.accountType)"
            @click="submit"
            elevation="0"
            large color="main" class="white--text">
          ویرایش اطلاعات
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {find, update} from "@Newfiling/module-admin/src/api";

export default {
  name: 'DesktopLogin',

  data() {
    return {
      initLoading: true,
      admin: {
        loading: false,
        nameandfamily: null,
        accountType: null,
        mobile: null,
        agencyname: null,
        phone: null,
        adress: null,
        userstatus: false,
      },
      adminTypes: [
        {
          id: 3,
          name: 'مدیر کل'
        },
        {
          id: 4,
          name: 'معاون'
        },
        {
          id: 5,
          name: 'منشی'
        },
        {
          id: 6,
          name: 'اپراتور'
        },

        {
          id: 7,
          name: 'ویزیتور'
        },
        {
          id: 8,
          name: 'پاسخگوی مشترکین'
        },


      ],
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست مدیران',
        disabled: false,
        href: '/panel/admins/list',
      },
      {
        text: 'ویرایش مدیر',
        disabled: true,
        href: '#',
      },
    ])
  },


  mounted() {
    this.fetchAdmin(this.$route.params.id);
  },

  methods: {
    async fetchAdmin(id) {
      this.initLoading = true;
      try {
        const res = (await find(id))?.data?.message || {};
        console.log({res})
        this.admin = {
          ...this.admin,
          ...res
        }

      } catch (e) {
        console.log(e)
      }
      this.initLoading = false;
    },

    async submit() {
      this.admin.loading = true;
      try {
        await update({
          ...this.admin,
          id: this.admin.id,
        })
        this.$toast.success('مدیر با موفقیت ویرایش شد.');
        await this.$router.replace('/panel/admins/list')
      } catch (e) {
        console.log(e)
      }
      this.admin.loading = false
    }
  },
}
</script>
